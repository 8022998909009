import React, { Component } from 'react';
import {Badge, Button, Card, CardBody, CardText, CardTitle} from 'reactstrap';
import {addHardwareUpgrade, removeCoins, updateCoinsPerSecond} from "../actions";
import connect from "react-redux/es/connect/connect";
import BigNumber from "bignumber.js";
import {bnToStr} from "../store/storeDependantFunctions";

const mapStateToProps = state => {
    return {
        coins: state.coins,
        hardwareUpgrades: state.hardwareUpgrades
    };
};

const mapDispatchToProps = dispatch => {
    return {
        addHardwareUpgrade: (id, number) => dispatch(addHardwareUpgrade(id, number)),
        removeCoins: n => dispatch(removeCoins(n)),
        updateCoinsPerSecond: () => dispatch(updateCoinsPerSecond())
    };
};

class ConnectedHardwareUpgrade extends Component {

    computePrice(n) {
        let inflationRate = BigNumber('2');
        let inflationStep = BigNumber('50');
        let inflationRateStep = BigNumber('500');

        let sold = this.props.hardwareUpgrades[this.props.id];
        let currentStep = sold.dividedBy(inflationStep).decimalPlaces(0);
        let currentInflationRateStep = sold.dividedBy(inflationRateStep).decimalPlaces(0);
        let price = BigNumber('0');

        for (let i = 0; i < n; i++) {
            if(currentStep.isZero()){
                price = price.plus(this.props.price);
            }else if(currentInflationRateStep.isZero()){
                price = price.plus(currentStep.multipliedBy(inflationRate).multipliedBy(this.props.price));
            }else{
                price = price.plus(currentStep.multipliedBy(inflationRate.exponentiatedBy(currentInflationRateStep)).multipliedBy(this.props.price));
            }
            sold = sold.plus(BigNumber('1'));
            currentStep = sold.dividedBy(inflationStep).decimalPlaces(0);
            currentInflationRateStep = sold.dividedBy(inflationRateStep).decimalPlaces(0);
        }
        return price;
    }

    buy(n) {
        let price = this.computePrice(n);
        if(this.props.coins.isGreaterThanOrEqualTo(price)) {
            this.props.addHardwareUpgrade(this.props.id, n);
            this.props.removeCoins(price);
            this.props.updateCoinsPerSecond();
        }
    }

    render() {
        return (
            <div>
                <Card>
                    <CardBody>
                        <CardTitle>
                            {this.props.title} <Badge color="secondary" pill>{this.props.hardwareUpgrades[this.props.id].toFormat()}</Badge>
                        </CardTitle>
                        <CardText>{this.props.desc} (+ {bnToStr(this.props.pycs)} PYC/s)</CardText>
                        <div>
                            <Button onClick={() => this.buy(BigNumber('1'))}>Buy ({bnToStr(this.computePrice(BigNumber('1')))} PYC)</Button>
                        </div>
                        <div>
                            <Button onClick={() => this.buy(BigNumber('10'))}>Buy x10({bnToStr(this.computePrice(BigNumber('10')))} PYC)</Button>
                        </div>
                        <div>
                            <Button onClick={() => this.buy(BigNumber('100'))}>Buy x100({bnToStr(this.computePrice(BigNumber('100')))} PYC)</Button>
                        </div>
                    </CardBody>
                </Card>
            </div>
        );
    }
}

const HardwareUpgrade = connect(mapStateToProps, mapDispatchToProps)(ConnectedHardwareUpgrade);

export default HardwareUpgrade;