import {
    ADD_COINS,
    ADD_HARDWARE,
    ADD_PER_SECOND,
    ADD_SOFTWARE, ADD_WALLET, CHANGE_DISPLAY_FORMAT,
    REMOVE_COINS, TIME_TRAVEL,
    UPDATE_COINS_PER_SECOND
} from "../config/constants";

export const addCoins = n => ({ type: ADD_COINS, payload: n });
export const addCoinsPerSecond = n => ({ type: ADD_PER_SECOND, payload: n });
export const addHardwareUpgrade = (id, number) => ({type: ADD_HARDWARE, payload: {id: id, number: number}});
export const removeCoins = n => ({ type: REMOVE_COINS, payload: n });
export const updateCoinsPerSecond = () => ({ type: UPDATE_COINS_PER_SECOND});
export const addSoftwareUpgrade = id => ({type: ADD_SOFTWARE, payload: id});
export const changeDisplayFormat = format => ({type: CHANGE_DISPLAY_FORMAT, payload: format});
export const timeTravel = () => ({ type: TIME_TRAVEL});
export const addWallet = size => ({type: ADD_WALLET, payload: size});