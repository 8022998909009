import {Jumbotron, Button, ButtonGroup, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem} from 'reactstrap';
import { connect } from "react-redux";
import React, { Component} from 'react'
import {addCoinsPerSecond, addCoins, changeDisplayFormat} from "./actions";
import HardwareUpgrades from "./components/HardwareUpgrades";
import SoftwareUpgrades from "./components/SoftwareUpgrades";
import {version} from "../package.json"
import BigNumber from "bignumber.js";
import AlienTechs from "./components/AlienTechs";
import {bnToStr} from "./store/storeDependantFunctions";

BigNumber.config({ ROUNDING_MODE: BigNumber.ROUND_FLOOR});

const mapStateToProps = state => {
    return {
        coins: state.coins,
        coinsPerSecond: state.coinsPerSecond,
        hardwareUpgrades : state.hardwareUpgrades,
        displayFormat: state.displayFormat,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        addCoins: n => dispatch(addCoins(n)),
        addCoinsPerSecond: n => dispatch(addCoinsPerSecond(n)),
        changeDisplayFormat: fmt => dispatch(changeDisplayFormat(fmt))
    };
};

class ConnectedApp extends Component {

    constructor(props) {
        super(props);

        this.fmtToggle = this.fmtToggle.bind(this);

        this.state = {
            upgradesToShow : "hardware",
            fmtDropdownOpen: false
        };
        console.log("T'essayes de checker la console petit malin.")
    }

    tick() {
        this.props.addCoins(this.props.coinsPerSecond);
    }

    componentDidMount() {
        this.interval = setInterval(() => this.tick(), 1000);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    addCoinsPerSecond(n) {
        this.props.addCoinsPerSecond(n);
    }

    addCoins(n) {
        this.props.addCoins(n);
    }

    switchUpgrades(up) {
        this.setState({
            upgradesToShow : up
        });
    }

    fmtToggle() {
        this.setState({
            fmtDropdownOpen: !this.state.fmtDropdownOpen
        });
    }

    setFmt(fmt) {
        switch(fmt){
            case "scientific":
                this.props.changeDisplayFormat(fmt);
                break;
            case "decimal":
            default:
                this.props.changeDisplayFormat("decimal");
        }
    }

    render () {
        let UpgradeToDisplay;
        switch (this.state.upgradesToShow) {
            case "hardware" :
                UpgradeToDisplay = <HardwareUpgrades />;
                break;
            case "software" :
                UpgradeToDisplay = <SoftwareUpgrades />;
                break;
            case "alien" :
                UpgradeToDisplay = <AlienTechs />;
                break;
            default:
                UpgradeToDisplay = <HardwareUpgrades />;
        }

        return (
            <div style={{"height" : "100%"}}>
                <header className="App-header">
                    <h1>PolyCoin Miner <small>v{version}</small></h1>
                </header>
                <Jumbotron>
                    <h2>Number of PYC</h2>
                    <h1>{bnToStr(this.props.coins)} (PYC/s : {bnToStr(this.props.coinsPerSecond)})</h1>
                    <p>
                        <Button color="primary" onClick={() => this.addCoins(BigNumber('1'))}>Mine</Button>
                    </p>
                </Jumbotron>
                <div>
                    <ButtonDropdown isOpen={this.state.fmtDropdownOpen} toggle={this.fmtToggle}>
                        <DropdownToggle caret>
                            Number format
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem onClick={() => this.setFmt("decimal")}>Decimal number</DropdownItem>
                            <DropdownItem onClick={() => this.setFmt("scientific")}>Scientific notation</DropdownItem>
                        </DropdownMenu>
                    </ButtonDropdown>
                </div>
                <ButtonGroup>
                    <Button onClick={() => this.switchUpgrades("hardware")}>Hardware</Button>
                    <Button onClick={() => this.switchUpgrades("software")}>Software</Button>
                    <Button onClick={() => this.switchUpgrades("alien")}>Alien Tech</Button>
                </ButtonGroup>
                <div style={{"overflow": "auto", "height" : "500px"}}>
                    {UpgradeToDisplay}
                </div>
            </div>
        );
    }
}



const App = connect(mapStateToProps, mapDispatchToProps)(ConnectedApp);

export default App;
