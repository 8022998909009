import BigNumber from "bignumber.js"

let hardwareUpgradesArrayInit = [
    {
        title : "GeForce 256 SDR",
        desc : "Useless Nvidia card for mining",
        pycs : BigNumber('1'),
        price : BigNumber('5')
    },
    {
        title : "GeForce2 Ultra",
        desc : "Better but still useless for mining",
        pycs : BigNumber('0'),
        price : BigNumber('0')
    },
    {
        title : "GeForce3 Ti500",
        desc : "In 2001 there were no blockchain",
        pycs : BigNumber('0'),
        price : BigNumber('0')
    },
    {
        title : "GeForce GTX 280",
        desc : "The first GTX as we know it. Also bitcoin was a thing",
        pycs : BigNumber('0'),
        price : BigNumber('0')
    },
    {
        title : "GeForce GTX 480",
        desc : "Close to the 1080ti, hype!!!",
        pycs : BigNumber('0'),
        price : BigNumber('0')
    },
    {
        title : "PS3",
        desc : "Arjen Lenstra would be proud",
        pycs : BigNumber('0'),
        price : BigNumber('0')
    },
    {
        title : "GeForce GTX 780 Ti",
        desc : "This is starting to look good",
        pycs : BigNumber('0'),
        price : BigNumber('0')
    },
    {
        title : "GeForce GTX TITAN Z",
        desc : "Unleash the power of the titan. (Also known as bad performance for price ratio)",
        pycs : BigNumber('0'),
        price : BigNumber('0')
    },
    {
        title : "GeForce GTX 980 Ti",
        desc : "4k starts to be a thing, wait what we are mining here?",
        pycs : BigNumber('0'),
        price : BigNumber('0')
    },
    {
        title : "GeForce GTX 1080 Ti",
        desc : "Here comes the moneyyy",
        pycs : BigNumber('0'),
        price : BigNumber('0')
    },
    {
        title : "Nvidia TITAN V - CEO Edition",
        desc : "CEO Edition because nobody knew it existed",
        pycs : BigNumber('0'),
        price : BigNumber('0')
    },
    {
        title : "GeForce RTX 2080 Ti",
        desc : "RTX ON",
        pycs : BigNumber('0'),
        price : BigNumber('0')
    },
    {
        title : "Dedicated PolyCoin miner card",
        desc : "People finally started building dedicated cards",
        pycs : BigNumber('0'),
        price : BigNumber('0')
    },
    {
        title : "Rack of Dedicated PolyCoin miner card",
        desc : "Why buy a single card when you can buy a full rack?",
        pycs : BigNumber('0'),
        price : BigNumber('0')
    },
    {
        title : "AWS Cluster",
        desc : "You are using most of the available AWS clusters for your mining",
        pycs : BigNumber('0'),
        price : BigNumber('0')
    },
    {
        title : "CERN hardware",
        desc : "CERN sees the potential in PolyCoin and is helping",
        pycs : BigNumber('0'),
        price : BigNumber('0')
    },
    {
        title : "Star Citizen capable hardware",
        desc : "The first computer to be able to run Star Citizen in 480p 30fps",
        pycs : BigNumber('0'),
        price : BigNumber('0')
    },
];
hardwareUpgradesArrayInit.forEach((v, i) => {
    if(i !== 0){
        hardwareUpgradesArrayInit[i] = {...v,
            price : BigNumber((5+5*(i+1)).toString()).multipliedBy(hardwareUpgradesArrayInit[i-1].price),
            pycs : BigNumber((5+2*(i+1)).toString()).multipliedBy(hardwareUpgradesArrayInit[i-1].pycs)}
    }else{
        return hardwareUpgradesArrayInit[i] =v
    }
});

export const hardwareUpgradesArray = hardwareUpgradesArrayInit;


export const softwareUpgradesList = {
    "For Loop optimisation" : {
      title : "For Loop optimisation",
      desc : "Your for loops are such efficient that you mine faster",
      mult : BigNumber('0.2'),
      price : BigNumber('1e5')
    },
    "CUDA" : {
        title : "CUDA",
        desc : "Optimising some of your code with CUDA",
        mult : BigNumber('0.5'),
        price : BigNumber('1e11')
    },
    "Assembly" : {
        title : "Assembly",
        desc : "When CUDA is too high level, you are left with optimizing assembly directly",
        mult : BigNumber('0.8'),
        price : BigNumber('1e18')
    },
    "Binary" : {
        title : "Binary",
        desc : "Who optimises assembly? Just code in binary directly",
        mult : BigNumber('1.1'),
        price : BigNumber('1e26')
    },
    "Transistors" : {
        title : "Transistors",
        desc : "Code directly from the transistors",
        mult : BigNumber('1.4'),
        price : BigNumber('1e34')
    },
};