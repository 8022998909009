import {
    ADD_COINS,
    ADD_HARDWARE,
    ADD_PER_SECOND, ADD_SOFTWARE, ADD_WALLET, CHANGE_DISPLAY_FORMAT,
    REMOVE_COINS, TIME_TRAVEL,
    UPDATE_COINS_PER_SECOND
} from "../config/constants";
import {hardwareUpgradesArray, softwareUpgradesList} from "../config/upgrades";
import {BigNumber} from "bignumber.js";
import {smallWalletSize, timeTravelMultiplicator} from "../components/AlienTechs";

export const initialState = {
    coins: BigNumber('0'),
    coinsPerSecond: BigNumber('0'),
    highest : BigNumber('0'),
    hardwareUpgrades : hardwareUpgradesArray.map(x => BigNumber('0')),
    softwareUpgrades : Object.assign(...Object.entries(softwareUpgradesList).map(([k, v]) => ({[k]: BigNumber('0')}))),
    displayFormat : "decimal",
    timeTravelBonus : BigNumber('0'),
    smallWallets : BigNumber('0')
};

const rootReducer = (state = initialState, action) => {
    switch (action.type) {
        case ADD_COINS:
            let newState = { ...state, coins: state.coins.plus(action.payload) };
            if(newState.coins.isGreaterThan(newState.highest)){
                newState = { ...newState, highest: newState.coins }
            }
            return newState;
        case ADD_PER_SECOND:
            return { ...state, coinsPerSecond: state.coinsPerSecond.plus(action.payload) };
        case ADD_HARDWARE:
            return {...state, hardwareUpgrades : state.hardwareUpgrades.map((x, i) => i === action.payload.id ? state.hardwareUpgrades[action.payload.id].plus(action.payload.number) : x) };
        case ADD_SOFTWARE:
            return {...state, softwareUpgrades : {...state.softwareUpgrades, [action.payload] : BigNumber('1') }};
        case REMOVE_COINS:
            return { ...state, coins: state.coins.minus(action.payload) };
        case UPDATE_COINS_PER_SECOND:
            let coinsPerSecond = BigNumber('0');
            state.hardwareUpgrades.forEach((v, i) => coinsPerSecond = coinsPerSecond.plus(hardwareUpgradesArray[i].pycs.multipliedBy(v)));
            let totalMult = BigNumber('1');
            Object.entries(state.softwareUpgrades).map(([k, v]) => totalMult = totalMult.plus(softwareUpgradesList[k].mult.multipliedBy(v)));
            coinsPerSecond = coinsPerSecond.multipliedBy(totalMult);
            if(!state.timeTravelBonus.isZero()){
                coinsPerSecond = coinsPerSecond.multipliedBy(state.timeTravelBonus.multipliedBy(timeTravelMultiplicator));
            }
            coinsPerSecond = BigNumber(coinsPerSecond.toFixed(0));
            return {...state, coinsPerSecond : coinsPerSecond};
        case CHANGE_DISPLAY_FORMAT:
            return {...state, displayFormat: action.payload};
        case TIME_TRAVEL:
            let startupCoins = BigNumber('0');
            startupCoins = startupCoins.plus(state.smallWallets.multipliedBy(smallWalletSize));
            return {...initialState, timeTravelBonus : state.timeTravelBonus.plus(BigNumber('1')), displayFormat : state.displayFormat, coins: startupCoins};
        case ADD_WALLET:
            switch(action.payload) {
                case "small":
                    return {...state, smallWallets: state.smallWallets.plus(BigNumber('1'))};
                default:
                    return state;
            }
        default:
            return state;
    }
};
export default rootReducer;