import { createStore } from "redux";
import { persistStore, persistReducer, createMigrate, createTransform} from 'redux-persist'
import createEncryptor from 'redux-persist-transform-encrypt'
import storage from 'redux-persist/lib/storage'
import rootReducer, {initialState} from "../reducers/index";
import {version} from '../../package.json'
import {BigNumber} from "bignumber.js";
import {setupStoreDependantFunctions} from "./storeDependantFunctions";
//import {aes_key} from "../config/aes_key.json"


/*const encryptor = createEncryptor({
    secretKey: "2FEFB7D00A3083591A01102C46C1D2A7",
    onError: function(error) {
        console.log(error);
    }
});*/

const appVToPersistV = {
  "0.2.0" : 0,
  "0.2.1" : 1,
};

const migrations = {
    0 : (state) => {return initialState},
    1 : (state) => {return state}
};

const BigNumberTransform = createTransform(
    (inboundState, key) => {return inboundState},
    (outboundState, key) => {
        switch (key) {
            case "coins":
            case "coinsPerSecond":
            case "highest":
            case "timeTravelBonus":
            case "smallWallets":
                return BigNumber(outboundState);
            case "hardwareUpgrades":
                return outboundState.map((v) => BigNumber(v));
            case "softwareUpgrades":
                return Object.assign(...Object.entries(outboundState).map(([k, v]) => ({[k]: BigNumber(v)})));
            default:
                return outboundState;
        }
    }
);


const persistConfig = {
    key: 'root',
    storage,
    version: appVToPersistV[version],
    migrate: createMigrate(migrations, { debug: true }),
    transforms : [BigNumberTransform]
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

let store = createStore(persistedReducer);
let persistor = persistStore(store);
//persistor.purge();

setupStoreDependantFunctions(store);

export default{ store, persistor };