import React, { Component } from 'react';
import HardwareUpgrade from "./HardwareUpgrade";
import {hardwareUpgradesArray} from "../config/upgrades";
import connect from "react-redux/es/connect/connect";

const mapStateToProps = state => {
    return {
        highest: state.highest
    };
};

class ConnectedHardwareUpgrades extends Component {
    render() {
        return (
            <div>
                {hardwareUpgradesArray.filter(x => x.price.isLessThanOrEqualTo(this.props.highest))
                    .map((v, i) => <HardwareUpgrade key={i} id={i} title={v.title} desc={v.desc} pycs={v.pycs} price={v.price} />)}
            </div>
        );
    }
}

const HardwareUpgrades = connect(mapStateToProps)(ConnectedHardwareUpgrades);

export default HardwareUpgrades;