import BigNumber from "bignumber.js";

let fmt = "decimal";

export function setupStoreDependantFunctions(store){
    store.subscribe(function(){
        const state = store.getState();
        switch (state.displayFormat) {
            case "scientific":
                fmt = state.displayFormat;
                break;
            case "decimal":
            default:
                fmt = "decimal";
        }
    });
}

export function bnToStr(bn) {
    switch (fmt) {
        case "scientific":
            return BigNumber(bn.toPrecision(6)).toExponential();
        case "decimal":
        default:
            return bn.toFormat();
    }
}