import React, { Component } from 'react';
import SoftwareUpgrade from "./SoftwareUpgrade";
import {softwareUpgradesList} from "../config/upgrades";
import connect from "react-redux/es/connect/connect";

const mapStateToProps = state => {
    return {
        highest: state.highest
    };
};

class ConnectedSoftwareUpgrades extends Component {
    render() {
        return (
            <div>
                {Object.entries(softwareUpgradesList)
                    .sort((a, b) => a[1].price.comparedTo(b[1].price))
                    .filter(x => x[1].price.isLessThanOrEqualTo(this.props.highest))
                    .map(([k, v]) => <SoftwareUpgrade key={k} title={v.title} desc={v.desc} mult={v.mult} price={v.price} />)}
            </div>
        );
    }
}

const SoftwareUpgrades = connect(mapStateToProps)(ConnectedSoftwareUpgrades);

export default SoftwareUpgrades;