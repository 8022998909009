import React, { Component } from 'react';
import {
    Badge,
    Button,
    Card,
    CardBody,
    CardText,
    CardTitle,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader
} from 'reactstrap';
import {addWallet, removeCoins, timeTravel, updateCoinsPerSecond} from "../actions";
import connect from "react-redux/es/connect/connect";
import BigNumber from "bignumber.js";
import {bnToStr} from "../store/storeDependantFunctions";

export const timeTravelMultiplicator = BigNumber('1.5');
export const smallWalletSize = BigNumber('500');

const smallWalletOriginalPrice = BigNumber('5e21');
const timeTravelOriginalPrice = BigNumber('1e20');

const mapStateToProps = state => {
    return {
        coins: state.coins,
        timeTravelBonus: state.timeTravelBonus,
        smallWallets : state.smallWallets
    };
};

const mapDispatchToProps = dispatch => {
    return {
        removeCoins: n => dispatch(removeCoins(n)),
        updateCoinsPerSecond: () => dispatch(updateCoinsPerSecond()),
        timeTravel : () => dispatch(timeTravel()),
        addWallet : size => dispatch(addWallet(size)),
    };
};

class ConnectedAlienTechs extends Component {

    constructor(props) {
        super(props);

        this.state = {
            timeTravelPrice : timeTravelOriginalPrice,
            smallWalletPrice : smallWalletOriginalPrice.multipliedBy(BigNumber('2').exponentiatedBy(this.props.smallWallets.plus(BigNumber('1')))),
            timeTravelModal : false
        };

        this.toggleTimeTravelModal = this.toggleTimeTravelModal.bind(this);
    }

    buy(price, buyType) {
        if(this.props.coins.isGreaterThanOrEqualTo(price)) {
            this.props.removeCoins(price);
            switch (buyType) {
                case "time travel":
                    this.toggleTimeTravelModal();
                    this.props.timeTravel();
                    break;
                case "small wallet":
                default:
                    this.props.addWallet("small");
                    this.setState({
                        smallWalletPrice : smallWalletOriginalPrice.multipliedBy(BigNumber('2').exponentiatedBy(this.props.smallWallets.plus(BigNumber('1'))))
                    });
                    break;
            }
        }
    }

    toggleTimeTravelModal() {
        this.setState({
            timeTravelModal: !this.state.timeTravelModal
        });
    }

    render() {
        return (
            <div>
                <Card>
                    <CardBody>
                        <CardTitle>
                            Time travel <Badge color="secondary" pill>{this.props.timeTravelBonus.toFormat()}</Badge>
                        </CardTitle>
                        <CardText>Alien tech allows you to travel back in the past and keep your knowledge of PolyCoin (Grants a permanent bonus of +{timeTravelMultiplicator.minus(BigNumber('1')).toFormat()}x PYC/s)</CardText>
                        <Button color="danger" disabled={!this.props.coins.isGreaterThanOrEqualTo(this.state.timeTravelPrice)} onClick={this.toggleTimeTravelModal}>Buy ({bnToStr(this.state.timeTravelPrice)} PYC)</Button>
                        <Modal isOpen={this.state.timeTravelModal} toggle={this.toggleTimeTravelModal} className={this.props.className}>
                            <ModalHeader toggle={this.toggleTimeTravelModal}>Are you sure you want to time travel?</ModalHeader>
                            <ModalBody>
                                If you time travel you will lose everything except the following:
                                <ul>
                                    <li>Previous time travel bonus</li>
                                    <li>Your virtual alien wallets will disappear but you will get the corresponding PolyCoins</li>
                                </ul>
                            </ModalBody>
                            <ModalFooter>
                                <Button color="danger" onClick={() => this.buy(this.state.timeTravelPrice, "time travel")}>Time Travel</Button>{' '}
                                <Button color="primary" onClick={this.toggleTimeTravelModal}>Cancel</Button>
                            </ModalFooter>
                        </Modal>
                    </CardBody>
                </Card>

                <Card>
                    <CardBody>
                        <CardTitle>
                            Small virtual alien wallet <Badge color="secondary" pill>{this.props.smallWallets.toFormat()}</Badge>
                        </CardTitle>
                        <CardText>When you time travel you can bring with you some PolyCoins ({smallWalletSize.toFormat()} PYC per wallet)</CardText>
                        <Button disabled={!this.props.coins.isGreaterThanOrEqualTo(this.state.smallWalletPrice)} onClick={() => this.buy(this.state.smallWalletPrice, "small wallet")}>Buy ({bnToStr(this.state.smallWalletPrice)} PYC)</Button>
                    </CardBody>
                </Card>
            </div>
        );
    }
}

const AlienTechs = connect(mapStateToProps, mapDispatchToProps)(ConnectedAlienTechs);

export default AlienTechs;