import React, { Component } from 'react';
import {Badge, Button, Card, CardBody, CardText, CardTitle} from 'reactstrap';
import {addSoftwareUpgrade, removeCoins, updateCoinsPerSecond} from "../actions";
import connect from "react-redux/es/connect/connect";
import {bnToStr} from "../store/storeDependantFunctions";

const mapStateToProps = state => {
    return {
        coins: state.coins,
        softwareUpgrades: state.softwareUpgrades
    };
};

const mapDispatchToProps = dispatch => {
    return {
        addSoftwareUpgrade: id => dispatch(addSoftwareUpgrade(id)),
        removeCoins: n => dispatch(removeCoins(n)),
        updateCoinsPerSecond: () => dispatch(updateCoinsPerSecond())
    };
};

class ConnectedSoftwareUpgrade extends Component {

    buy() {
        if(this.props.coins.isGreaterThanOrEqualTo(this.props.price) && this.props.softwareUpgrades[this.props.title].isZero()) {
            this.props.addSoftwareUpgrade(this.props.title);
            this.props.removeCoins(this.props.price);
            this.props.updateCoinsPerSecond();
        }
    }

    render() {
        return (
            <div>
                <Card>
                    <CardBody>
                        <CardTitle>{this.props.title} {this.props.softwareUpgrades[this.props.title].isZero() ? "" : <Badge color="success" pill>Ok</Badge>}</CardTitle>
                        <CardText>{this.props.desc} (+ {this.props.mult.toFormat()}x PYC/s)</CardText>
                        <Button onClick={() => this.buy()}>Buy ({bnToStr(this.props.price)} PYC)</Button>
                    </CardBody>
                </Card>
            </div>
        );
    }
}

const SoftwareUpgrade = connect(mapStateToProps, mapDispatchToProps)(ConnectedSoftwareUpgrade);

export default SoftwareUpgrade;